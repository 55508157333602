<template>
<div class="modal_bg" v-if="isShowDia">
  <div class="modal modal_631">
    <a href="javascript:;" class="icon_close" @click="handleCloseDialog" />
    <div class="modal_title">Winning Team Rewards</div>
    <p>Congratulations! Your team has come out victorious! </p>
    <p>The following rewards will be sent to you </p>
    <p>via in-game mailbox within 7 working days:</p>
    <a href="javascript:;" :class="['modal_btn', 'btn_ok']" @click="handleCloseDialog" />
  </div>
</div>
</template>

<script>
export default {
  name: "winTeamModal",
  props: {
    isShowDia: Boolean
  },
  methods: {
    handleCloseDialog() {
      this.$emit('closeDialog', 'showWinTeamModal');
    }
  }
}
</script>

<style scoped lang="scss">
.modal_title{
  margin-bottom: 20px;
}
p{
 line-height: 40px;
 color: #fff;
 font-size: 24px;
 text-align: center;
}

.btn_ok{
  position: absolute;
  left: 50%;
  margin-left: 149px;
  bottom: 52px;
}
</style>
