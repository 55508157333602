<template>
<div class="modal_bg modal_bg_tips" v-if="isShowDia">
  <div class="modal modal_403">
    <a href="javascript:;" class="icon_close" @click="handleCloseDialog" />
    <div class="modal_title">{{tipsData.title}}</div>
    <div class="modal_des">
      <p v-for="(item, index) in tipsData.des" :key="index">{{item}}</p>
    </div>
    <a href="javascript:;" :class="['modal_btn', 'btn', `btn_${tipsData.btn}`]" @click="handleClickBtn" />
  </div>
</div>
</template>

<script>
export default {
  name: "tipsModal",
  props: {
    isShowDia: Boolean,
    tipsData: Object
  },
  methods: {
    handleCloseDialog() {
      this.$emit('closeDialog', 'showTipsModal');
    },
    handleClickBtn(){
      if (this.tipsData.title === 'Join the Team') {
        this.$emit('joinTeam', this.tipsData.data)
      } else {
        this.handleCloseDialog()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.btn{
  display: block;
  width: 298px;
  height: 85px;
  position: absolute;
  left: 50%;
  margin-left: -149px;
  top: 275px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.modal_des{
  height: 174px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;

  p{
    width: 100%;
    text-align: center;
    font-size: 26px;
    color: #fff;
    line-height: 34px;
  }
}
</style>
