<template>
<div class="modal_bg" v-if="isShowDia">
  <div class="modal modal2" id="modal2" @click="handleCloseDialog">
<!--    <a href="javascript:;" class="icon_close" @click="handleCloseDialog" />-->
    <img :src="require(`@/views/images/works/img-work-drawing-${img}.png`)" alt="card" class="img-card" />
  </div>
</div>
</template>

<script>
export default {
  name: "bigImgModal",
  props: {
    isShowDia: Boolean,
    img: Number
  },
  data() {
    return {

    }
  },
  methods: {
    handleCloseDialog(event) {
      if (event.target.id === 'modal2') {
        this.$emit('closeDialog', 'showBigImgModal');
      }
    },

  }
}
</script>

<style scoped lang="scss">
.modal2 {
  width: 100%!important;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .img-card {
    display: block;
    max-width: 80%;
    max-height: 80%;
    margin: 0 auto;
  }
}

</style>
