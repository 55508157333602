<template>
<div class="modal_bg" v-if="isShowDia">
  <div class="modal modal_485">
    <a href="javascript:;" class="icon_close" @click="handleCloseDialog" />
    <div class="modal_title">Log In</div>
    <ul class="login_info">
      <li>
        <p>Account ID:</p>
        <input type="text" placeholder="Please enter your game account." v-model="login.account_id" @input="handleInput"/>
      </li>
      <li>
        <p>Game Server:</p>
        <input type="text" placeholder="Server id." v-model="login.zone_id" @input="handleInput"/>
      </li>
      <li>
        <p>Verification Code:</p>
        <input type="text" placeholder="Enter the code." v-model="login.verify_code" @input="handleInput"/>
        <a href="javascript:;" :class="['btn_getCode', isNull ? 'no' : '']" @click="handleGetCode">
          {{countdownTime < 60 ? countdownTime + 'S' : 'Send'}}
        </a>
      </li>
    </ul>
    <p class="tips">*Please check the verification code in your in-game mailbox.</p>
    <a href="javascript:;" :class="['modal_btn', 'btn_login', isAllNull ? 'null' : '']" @click="handleLogin" />
  </div>
</div>
</template>

<script>
export default {
  name: "loginModal",
  props: {
    isShowDia: Boolean
  },
  data() {
    return{
      isNull: true,
      isAllNull: true,
      countdownTime: 60,
      interval: null,
      login: {
        account_id: '',
        zone_id: '',
        verify_code: ''
      }
    }
  },
  computed: {
    // 只允许输入数字，其他字符被忽略
    accountValue() {
      return this.login.account_id.replace(/[^\d]/g, '');
    },
    zoneValue() {
      return this.login.zone_id.replace(/[^\d]/g, '');
    },
    verifyValue() {
      return this.login.verify_code.replace(/[^\d]/g, '');
    },
  },
  methods: {
    handleCloseDialog() {
      this.$emit('closeDialog', 'showLoginModal');
    },
    handleInput() {
      this.login.account_id = this.accountValue;
      this.login.zone_id = this.zoneValue;
      this.login.verify_code = this.verifyValue;
      this.isNull = !(this.login.account_id.length > 0 && this.login.zone_id.length > 0);
      this.isAllNull = !(this.login.account_id.length > 0 && this.login.zone_id.length > 0 && this.login.verify_code.length > 0);
    },
    handleGetCode() {
      if(!this.isNull) {
        if (this.countdownTime === 60) {
          this.$emit('getCode', this.login);
          this.interval = setInterval(() => {
            if(this.countdownTime - 1 < 0) {
              window.clearInterval(this.interval);
              this.countdownTime = 60;
            } else {
              this.countdownTime = this.countdownTime - 1;
            }
          }, 1000);
        } else {
          return false
        }
      }
    },
    handleLogin() {
      if (!this.isNull && this.login.verify_code.length > 0) {
        this.$emit('login', this.login);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.login_info{
  width: 688px;
  margin: 25px auto 0;

  li{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;

    p{
      width: 250px;
      font-size: 26px;
      color: #fcf9dc;
      text-align: right;
    }

    input{
      height: 50px;
      border:1px solid #c9a55f;
      background: #3e2a17;
      border-radius: 5px;
      line-height: 48px;
      padding-left: 20px;
      font-size: 22px;
      color: #b3a680;
      width: 198px;
      margin: 0 15px;
    }

    &:nth-child(1) {
      input{
        width: 408px;
      }
    }

    .btn_getCode{
      width: 115px;
      height: 50px;
      background: url("@/components/Modal/images/btn-getCode.png") no-repeat center/cover;
      font-size: 25px;
      color: #fdf3a7;
      text-align: center;
      line-height: 50px;

      &.no{
        -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
        filter: grayscale(100%);
      }
    }
  }

}

.tips{
  font-size: 22px;
  color: #e2d69e;
  text-align: center;
}

.btn_login{
  display: block;
  width: 339px;
  height: 85px;
  background: url("@/components/Modal/images/btn-login.png") no-repeat center/cover;
  margin: 10px auto 0;

  &.null{
    -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
    filter: grayscale(100%);
    pointer-events: none;
  }
}
</style>
