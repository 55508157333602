<template>
  <div class="page">
    <div v-if="!isShowPage" class="wrap2">
      <video class="video" id="video2" autoplay muted loop :src="require('./images/kv2.mp4')" />
      <a href="https://www.watcherofrealms.com/" class="logo" target="_blank"/>
      <div class="kv_center">
        <a href="javascript:;" class="btn btn-download" @click="handleClickDownload('yy')"/>
        <img src="./images/img-downCode2.png" alt="download app QR Code" class="img-downCode"/>
      </div>
    </div>
    <div v-if="isShowPage" :class="['wrap', isShowDialog ? 'hidden' : '']">
      <div v-if="isShowNavBar" class="nav">
        <a
            v-for="(item, index) in navList"
            :key="index" href="javascript:;"
            @click="handleGoAnchor(`modules_${index + 1}`)"
            :class="index === navActive && 'active'"
        >
          {{item}}
        </a>
        <span class="top" @click="handleGoAnchor()"/>
      </div>
      <div id="modules_kv" class="modules modules_kv">
        <video class="video" id="video" autoplay muted loop :src="require('./images/kv.mp4')" />
        <a href="https://www.watcherofrealms.com/" class="logo" target="_blank"/>
        <div class="log-box">
          <div v-if="!isLogin" class="log">
            <p>Please</p>
            <a href="javascript:;" class="login" @click="handleOpenDialog('showLoginModal')">log in</a>
          </div>
          <div v-else class="log">
            <p>{{userData.hide_account_id}}</p>
          </div>
        </div>
<!--        <div class="slogan" />-->
        <div class="kv_center">
          <a href="javascript:;" class="btn btn-choice" @click="handleGoAnchor('modules_1', 'JoinTeam')"/>
          <a href="javascript:;" class="btn btn-download" @click="handleClickDownload('gc')"/>
          <img src="./images/img-downCode.png" alt="download app QR Code" class="img-downCode"/>
        </div>
        <img src="./images/img-kv-prize.png" class="img-kv-prize" alt="kv prize" />
      </div>
      <div id="modules_1" class="modules modules_1">
        <p class="text">Some exhilarating influencers from the WoR community are engaged
          in a fierce battle! Maybe they could use a hand...</p>
        <div class="total_points">
          <div class="total_box">
            <div class="total total_l" :style="{width: totalWidth.left + '%'}"><span>{{groupList.lan_total_level_integral}}</span></div>
            <div class="total total_r" :style="{width: totalWidth.right + '%'}"><span>{{groupList.hong_total_level_integral}}</span></div>
          </div>
        </div>
        <div class="brigade_box">
          <div :class="['brigade', 'brigade_l', isEnd && groupList.lan_total_level_integral > groupList.hong_total_level_integral && 'win']">
            <span class="icon_join" v-if="userData.is_join === 1"/>
            <div class="total_ranks">
              <h6>Total stages Cleared:</h6>
              <p>{{groupList.lan_total_level_integral}}</p>
            </div>
            <div class="ranks_list">
              <div class="ranks_item"
                   v-for="(item, index) in groupList.lan_list"
                   :key="index">
                <p class="id">{{index + 1}}.{{ item.hide_account_id }}</p>
                <p class="level">{{ item.user_total_integral }}</p>
              </div>
            </div>
            <span class="icon_tips icon_tips_l"/>
          </div>
          <div :class="['brigade', 'brigade_r', isEnd && groupList.hong_total_level_integral > groupList.lan_total_level_integral && 'win']">
            <span class="icon_join" v-if="userData.is_join === 2"/>
            <div class="total_ranks">
              <h6>Total stages Cleared:</h6>
              <p>{{groupList.hong_total_level_integral}}</p>
            </div>
            <div class="ranks_list">
              <div class="ranks_item"
                   v-for="(item, index) in groupList.hong_list"
                   :key="index">
                <p class="id">{{index + 1}}.{{ item.hide_account_id }}</p>
                <p class="level">{{ item.user_total_integral }}</p>
              </div>
            </div>
            <span class="icon_tips icon_tips_r"/>
          </div>
        </div>
        <div class="join_box">
          <a href="javascript:;" :class="['btn_join', 'btn_join_l', userData.is_join !== 0 && 'disabled']" @click="handleJoinTips(groupList.lan_group_code, 1)" />
          <a href="javascript:;" :class="['btn_join', 'btn_join_r', userData.is_join !== 0 && 'disabled']" @click="handleJoinTips(groupList.hong_group_code, 2)" />
        </div>
        <div class="rule_box1">
          <h6 class="tit">Team members can earn one point for completing the following tasks:</h6>
          <ul class="rule_text">
            <li>Event Period 12/15/2023 - 12/31/2023</li>
            <li>
              Clear 1 Campaign stage - 1 point (repeat clears do not count)
            </li>
            <li>
              Clear 1 Void Rift stage - 1 point (repeat clears do not count)
            </li>
            <li>
              Invite friends into the team (daily max: 20 friends)
            </li>
          </ul>
          <ul class="task_list">
            <li>
              <p class="title">Campaign Stages Completed</p>
              <p class="num">{{userData.level_num || 0}}</p>
              <a href="javascript:;" class="btn_task btn_task_play" @click="handlePlayGame"/>
            </li>
            <li>
              <p class="title">Friends Invited to Team (Daily)</p>
              <p class="num"><span>{{taskData.day_invite_num}}</span>/20</p>
              <a href="javascript:;" class="btn_task btn_task_share" @click="handleShowShareModal" />
            </li>
          </ul>
        </div>
        <div class="prize_box" />
      </div>
      <div id="modules_2" class="modules modules_2">
        <div class="role2"/>
        <div class="prize_box1"/>
        <div class="ranks_box">
          <div class="my_data">
            <p>My Ranking: {{ranksList?.userInfo?.rank}}</p>
            <p>My Total Stages Cleared:{{ranksList?.userInfo?.total_level_num}}</p>
          </div>
          <ul class="ranks_list">
            <li
                v-for="(item, index) in ranksList.rankList"
                :key="index"
                class="ranks_item"
            >
              <p class="rank_index">{{ index + 1 > 9 ? index + 1 : `0${index + 1}` }}</p>
              <p class="rank_user">{{ item.hide_account_id }}</p>
              <p class="rank_total">{{ item.user_total_integral }}</p>
            </li>
          </ul>
        </div>
        <div class="ranks_tips">
          <p>Total Stages Cleared = Stages Cleared Individually + Stages Cleared By Friends You Invited / 5</p>
        </div>
      </div>
      <div id="modules_3" class="modules modules_3">
        <div class="work-box">
          <ul class="rule_text">
            <li>
              Voting Period: 12/15/2023 ~ 12/31/2023 (UTC-8)
            </li>
            <li>
              Commanders can complete Daily Quests to earn votes for FREE. Each Commander can
              earn up to a maximum of 6 votes per day. These votes can be used to vote for your
              favorite AIGC work(s).
            </li>
            <li>
              Commanders may vote 1 time per day. Please note that at the end of every day
              (00:00, midnight, UTC-8), your votes will expire, so don't forget to cast your vote!
            </li>
            <li>
              During the event, 5 lucky Commanders will be randomly selected each day to win
              a Lucky Prize,consisting of Diamond * 100, Stamina * 200, and 4-Star Psychic Power *1.
            </li>
            <li>
              The list of winners will be published on our official WoR Discord, Facebook, Reddit,
              and YouTube channels.
            </li>
          </ul>
          <div class="tips">
            <h6>Note: </h6>
            <p>
              Before voting, you will need to complete the Watcher of Realms in-game email binding
              process to verify your account. If, after completion, you switch browsers or devices,
              you may be asked to verify your account again.
            </p>
          </div>
          <ul class="task_list">
            <li>
              <p class="title">AIGC Prompts</p>
              <p class="num"><span>{{taskData.channel_task}}</span>/1</p>
              <p class="info">Vote +1</p>
              <a
                  href="javascript:;"
                  :class="['btn_task', 'btn_task_go2', taskData.channel_task >= 1 && 'btn_task_end']"
                  @click="handleClickTask('channel_task', 1)"
              />
            </li>
            <li>
              <p class="title">AI Expertise Recruitment</p>
              <p class="num"><span>{{taskData.browse_task}}</span>/1</p>
              <p class="info">Vote +1</p>
              <a
                  href="javascript:;"
                  :class="['btn_task', 'btn_task_go2', taskData.browse_task >= 1 && 'btn_task_end']"
                  @click="handleClickTask('browse_task', 2)"
              />
            </li>
            <li>
              <p class="title">Short Video Master Wanted</p>
              <p class="num"><span>{{taskData.fb_task}}</span>/1</p>
              <p class="info">Vote +1</p>
              <a
                  href="javascript:;"
                  :class="['btn_task', 'btn_task_go2', taskData.fb_task >= 1 && 'btn_task_end']"
                  @click="handleClickTask('fb_task', 3)"
              />
            </li>
            <li>
              <p class="title">WoR Official TikTok</p>
              <p class="num"><span>{{taskData.tiktok_task}}</span>/1</p>
              <p class="info">Vote +1</p>
              <a
                  href="javascript:;"
                  :class="['btn_task', 'btn_task_go2', taskData.tiktok_task >= 1 && 'btn_task_end']"
                  @click="handleClickTask('tiktok_task', 4)"
              />
            </li>
            <li>
              <p class="title">WoR Official Youtube Channel</p>
              <p class="num"><span>{{taskData.youtobe_task}}</span>/1</p>
              <p class="info">Vote +1</p>
              <a
                  href="javascript:;"
                  :class="['btn_task', 'btn_task_go2', taskData.youtobe_task >= 1 && 'btn_task_end']"
                  @click="handleClickTask('youtobe_task', 5)"
              />
            </li>
            <li>
              <p class="title">WoR Official Subreddit</p>
              <p class="num"><span>{{taskData.reddit_task}}</span>/1</p>
              <p class="info">Vote +1</p>
              <a
                  href="javascript:;"
                  :class="['btn_task', 'btn_task_go2', taskData.reddit_task >= 1 && 'btn_task_end']"
                  @click="handleClickTask('reddit_task', 6)"
              />
            </li>
          </ul>
        </div>
        <div class="vote-box">
          <h1 class="vote-title">Remaining Votes: <span>{{taskData.vote_num}}</span></h1>
          <div class="vote-top">
            <div class="sort-box">
              <div class="sort">
                <select @change="handleChangeWorkSort" v-model="workSort">
                  <option value="1">Randomly Sort</option>
                  <option value="2">Poll Standings</option>
                </select>
              </div>
              <p>Scroll to see more works (Total: 30)</p>
            </div>
            <a href="javascript:;" class="btn_go_create" @click="handleClickComeCreate" />
          </div>
          <div class="vote-main">
            <ul class="card-list">
              <li v-for="(item, index) in cardList" :key="index" class="card-item">
                <div class="card-index">{{item.id}}</div>
                <div class="card">
                  <div class="card-top">
                    <img :src="require(`@/views/images/works/img-work-cover-${item.works_id}.png`)" :alt="item.first_name" />
                    <a href="javascript:;" class="icon-enlarge" @click="handleClickShowBigImg(item.works_id)" />
                    <div class="name">
                      <p>{{item.first_name}}</p>
                      <p>{{item.last_name}}</p>
                    </div>
                  </div>
                  <div class="card-bottom">
                    Votes: <span>{{item.vote_num}}</span>
                  </div>
                </div>
                <a href="javascript:;" class="btn-vote" @click="handleClickVote(item.works_id)" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="modules_4" class="modules modules_4">
        <ul class="text-list">
          <li>We have been listening to everyone's comments carefully.</li>
          <li>We also had the special opportunity to host a very open discussion with the highly respected</li>
          <li>member of the WoR community - MaBucket!</li>
          <li>On that day, we received many fantastic suggestions from our player base --</li>
        </ul>
        <div class="plan-box">
          <div class="video-box" @click="handlePlayVideo('AXS6lbWXSB8')">
            <img src="./images/img-videoCover.png" alt="video cover" />
          </div>
          <img src="./images/img-text.png" alt="" class="img-text" />
          <div class="plan-swiper-box">
            <div class="plan-swiper swiper-container">
              <div class="swiper-wrapper">
                <div
                    v-for="(item, index) in planList"
                    :key="index"
                    class="plan-item swiper-slide"
                >
                  <img :src="item.img" alt="item.name" />
                </div>
              </div>
            </div>
            <div class="pagination-box">
              <a href="javascript:;" class="btn-swiper btn-swiper-prev btn-plan-prev"></a>
              <div class="plan-pagination" />
              <a href="javascript:;" class="btn-swiper btn-swiper-next btn-plan-next"></a>
            </div>
          </div>
        </div>
        <div class="role3"/>
      </div>
      <div id="modules_5" class="modules modules_5">
        <h1 class="module5-slogan" />
        <h6>Salute, Commanders! Our annual update is about to drop. It's time to
          unveil the surprise we've prepared for you all this winter!</h6>
        <h6>Of course! With X'mas around the corner,<br/>
          more exciting and fun events are coming too!</h6>
        <div class="newVersion-swiper-box">
          <div class="newVersion-swiper swiper-container" id="newVersion-swiper">
            <div class="swiper-wrapper">
              <div
                  v-for="(item, index) in newVersionList"
                  :key="index"
                  class="newVersion-item swiper-slide"
              >
                <img :src="item.img" alt="item.name" />
              </div>
            </div>
          </div>
        </div>
        <div class="pagination-box">
          <a href="javascript:;" class="btn-swiper btn-swiper-prev btn-newVersion-prev"></a>
          <div class="newVersion-pagination" />
          <a href="javascript:;" class="btn-swiper btn-swiper-next btn-newVersion-next"></a>
        </div>
        <ul class="text-list">
          <li>We have been listening to your suggestions carefully.</li>
          <li>And we care deeply about your gaming experience.</li>
          <li>Rest assured that going forward,</li>
          <li>WoR will continue to create new and exciting </li>
          <li>experiences for Commanders all around the world!</li>
        </ul>
      </div>
      <div v-if="!isEnd" class="countdown">
        <p>{{activityTime}}</p>
      </div>
      <a href="javascript:;" class="icon_rule" @click="handleOpenDialog('showRuleModal')"/>
      <a href="javascript:;" class="btn-lookAdd" @click="handleClickMyEmail" />
    </div>
    <loginModal
        :isShowDia="dialogState.showLoginModal"
        @closeDialog="handleCloseDialog"
        @getCode="handleGetCode"
        @login="handleLogin"
    />
    <ruleModal :isShowDia="dialogState.showRuleModal" @closeDialog="handleCloseDialog"/>
    <winTeamModal :isShowDia="dialogState.showWinTeamModal" @closeDialog="handleCloseDialog"/>
    <shareModal :isShowDia="dialogState.showShareModal" @closeDialog="handleCloseDialog" @clickShare="handleClickShare"/>
    <linkModal
        :isShowDia="dialogState.showLinkModal"
        @closeDialog="handleCloseDialog"
        :title="shareData.title"
        :text="shareData.text"
        :link="shareData.link"
        @copy="handleCopyText"
    />
    <tipsModal
        :isShowDia="dialogState.showTipsModal"
        @closeDialog="handleCloseDialog"
        :tipsData="tipsModalData"
        @joinTeam="handleJoinGroup"
    />
    <AICreateModal
        :isShowDia="dialogState.showAICreateModal"
        @closeDialog="handleCloseDialog"
    />
    <emailModal
        :isShowDia="dialogState.showEmailModal"
        @closeDialog="handleCloseDialog"
        @submitEmail="handleSubmitEmail"
    />
    <look-email-modal
        :isShowDia="dialogState.showLookEmailModal"
        @closeDialog="handleCloseDialog"
        @editEmail="handleShowEditEmail"
        :emailData="userData.mailbox"
    />
    <big-img-modal
        :isShowDia="dialogState.showBigImgModal"
        @closeDialog="handleCloseDialog"
        :img="showImg"
    />
    <videoModal :isShowDia="dialogState.showVideoModal" :videoLink="playVideoLink" @closeDialog="handleCloseDialog" />
  </div>
</template>

<script>
import loginModal from "@/components/Modal/login-modal/index.vue";
import ruleModal from "@/components/Modal/rule-modal/index.vue";
import tipsModal from "@/components/Modal/tips-modal/index.vue";
import winTeamModal from "@/components/Modal/winTeam-modal/index.vue";
import linkModal from "@/components/Modal/link-modal/index.vue";
import shareModal from "@/components/Modal/share-modal/index.vue";
import videoModal from "@/components/Modal/video-modal/index.vue";
import AICreateModal from "@/components/Modal/AICreate-modal/index.vue";
import emailModal from "@/components/Modal/email-modal/index.vue";
import lookEmailModal from "@/components/Modal/lookEmail-modal/index.vue";
import bigImgModal from "@/components/Modal/bigImg-modal/index.vue";
import request from "@/utils/request";
import countryJson from "@/utils/country.json";
import $$gaTracker from "@/utils/google-analytics-tracker"
import Swiper from 'swiper';
import 'swiper/js/swiper.min.js';
import 'swiper/css/swiper.min.css';

export default {
  name: "Main",
  components: {
    loginModal,
    ruleModal,
    tipsModal,
    winTeamModal,
    linkModal,
    shareModal,
    videoModal,
    AICreateModal,
    emailModal,
    lookEmailModal,
    bigImgModal
  },
  props: {},
  data() {
    return {
      isEnd: false,
      isShowPage: true,
      downloadLink: 'https://www.watcherofrealms.com/pclanding?gclid=webpage', // 公测下载地址
      downloadLink2: 'https://app.adjust.com/s8bh0dy_kdu6ngu?campaign=enpre&adgroup=preweb', // 悬念站下载地址
      navList: ['Team Ranking', 'Individual Ranking', 'WOR AIGC EVENT is here', 'Your Voice Matters', 'Something NEW'],
      navActive: 0,
      isShowNavBar: false,
      isLogin: false,
      uuid: '',
      activityTime: 0, // 倒计时
      loginData: {
        account_id: null,
        zone_id: null,
        verify_code: null,
        zone_type: 1,
        invite_code: ""
      },
      groupList: {}, // 战队排行
      playVideoLink: '',
      ranksList: [], // 个人排行,
      dialogState: { // 弹窗状态管理
        showLoginModal: false,
        showRuleModal: false,
        showTipsModal: false,
        showWinTeamModal: false,
        showLinkModal: false,
        showShareModal: false,
        showVideoModal: false,
        showAICreateModal: false,
        showEmailModal: false,
        showLookEmailModal: false,
        showBigImgModal: false
      },
      isShowDialog: false,
      tipsModalData: {
        title: 'Tips',
        des: [],
        btn: '',
        data: {}
      },
      shareData: {
        title: 'Invite Friends',
        text: ['Share the following link with your friends to get Points!', 'Friends invited need to connect their game accounts on this page.'],
        link: ''
      },
      userData: {}, // 用户信息
      countryList: [],
      param:[],
      mobileLink: 'https://dec-event.watcherofrealms.com/m/',
      totalWidth: {
        left: 0,
        right: 0
      },
      cardList: [],
      planList: [
        {
          img: require('./images/img-plan1.png'),
          name: 'plan img 1'
        },
        {
          img: require('./images/img-plan2.png'),
          name: 'plan img 2'
        },
        {
          img: require('./images/img-plan3.png'),
          name: 'plan img 3'
        },
        {
          img: require('./images/img-plan4.png'),
          name: 'plan img 4'
        }
      ],
      planSwiper: null,
      newVersionList: [
        {
          name: 'img newVersion 1',
          img: require('./images/img-newVersion1.png')
        },
        {
          name: 'img newVersion 2',
          img: require('./images/img-newVersion2.png')
        },
        {
          name: 'img newVersion 3',
          img: require('./images/img-newVersion3.png')
        }
      ],
      newVersionSwiper: null,
      taskData: {
        day_invite_num: 0, //当天邀请次数
        reddit_task: 0, //关注查看reddit主页
        youtobe_task: 0, //关注查看油管主页
        tiktok_task: 0, //关注查看tiktok主页
        fb_task: 0, //关注查看fb任务
        channel_task: 0, //收集channel任务
        browse_task: 0, //DC浏览
      },
      isVoteIng: false,
      workSort: 1,
      showImg: null
    }
  },
  beforeCreate(){

  },
  created() {
    this.handleGetActivityTime();
    this.handleGetUrlParams();
    function getUserAgent() {
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return userAgent.toLowerCase();
    }
    function isMobile() {
      let userAgent = getUserAgent();
      return (/(android|bb\d+|meego).+mobile|ipad|pad|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series([46])0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(userAgent) || // eslint-disable-next-line
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br([ev])w|bumb|bw-([nu])|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do([cp])o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly([-_])|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-([mpt])|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c([- _agpst])|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac([ -\/])|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja([tv])a|jbro|jemu|jigs|kddi|keji|kgt([ \/])|klon|kpt |kwc-|kyo([ck])|le(no|xi)|lg( g|\/([klu])|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t([- ov])|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30([02])|n50([025])|n7(0([01])|10)|ne(([cm])-|on|tf|wf|wg|wt)|nok([6i])|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan([adt])|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c([-01])|47|mc|nd|ri)|sgh-|shar|sie([-m])|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel([im])|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c([- ])|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(userAgent.substr(0, 4)));
    }

    if (isMobile()) {
      window.location.replace(this.mobileLink)
    }
    this.$nextTick(() => {
      this.uuid = localStorage.getItem('uuid');
      if (this.uuid !== '' && this.uuid !== null) {
        this.handleGetUserInfo();
      }
      this.handleGetRankList();
      this.handleGetWorkList(); // 获取作品列表
      this.handleGetGroupList(); // 获取战队排行
      this.countryList = countryJson;
      if (this.isShowPage) {
        this.handleShowNav(document.documentElement.scrollTop || document.body.scrollTop);
        this.handleCreatePlanSwiper();
        this.handleCreateNewVersionSwiper();
        window.addEventListener('scroll', () => {
          this.handleMonitorScroll();
        });
      } else {
        window.removeEventListener('scroll', () => {
          this.handleMonitorScroll();
        })
      }
    });
    },
  // watch: {
  //   isShowPage() {
  //     this.$nextTick(() => {
  //       this.uuid = localStorage.getItem('uuid');
  //       if (this.uuid !== '' && this.uuid !== null) {
  //         this.handleGetUserInfo();
  //       }
  //       this.handleGetRankList();
  //       this.handleGetWorkList(); // 获取作品列表
  //       this.handleGetGroupList(); // 获取战队排行
  //       this.countryList = countryJson;
  //       if (this.isShowPage) {
  //         this.handleShowNav(document.documentElement.scrollTop || document.body.scrollTop);
  //         this.handleCreatePlanSwiper();
  //         this.handleCreateNewVersionSwiper();
  //         window.addEventListener('scroll', () => {
  //           this.handleMonitorScroll();
  //         });
  //       } else {
  //         window.removeEventListener('scroll', () => {
  //           this.handleMonitorScroll();
  //         })
  //       }
  //     });
  //   }
  // },
  mounted() {

  },
  methods: {
    handleTest() {

    },
    // 通用关闭弹窗方法
    handleCloseDialog(dialog) {
      this.dialogState[dialog] = false;
      this.isShowDialog = false;
      if (dialog === 'showTipsModal') {
        this.tipsModalData.title = 'Tips'
      }
      // window.removeEventListener('wheel', this.handleWheel)
    },
    // 通用打开弹窗方法
    handleOpenDialog(dialog) {
      this.dialogState[dialog] = true;
      this.isShowDialog = true;
      if (dialog === 'showLoginModal') {
        $$gaTracker.clickLoginBtn();
      }
      // if (dialog !== 'showRuleModal' || dialog !== 'showLotteryResult5Modal') {
      //   window.addEventListener('wheel', this.handleWheel, {
      //     passive: false
      //   })
      // }
    },
    // 打开弹窗时阻止鼠标滚轮默认行为
    handleWheel(e) {
      e.preventDefault();
    },
    // 点击查看大图
    handleClickShowBigImg(img) {
      this.showImg = img;
      this.dialogState.showBigImgModal = true
    },
    // 设置localStorage
    handleSetStorage(key, value) {
      localStorage.setItem(key, value);
    },
    // 下载游戏
    handleClickDownload(type) {
      $$gaTracker.clickDownLoad();
      if (type === 'yy') {
        window.open(this.downloadLink2)
      } else {
        window.open(this.downloadLink)
      }
    },
    // 获取链接参数
    handleGetUrlParams() {
      const search = window.location.search;
      if (!search) return;
      const paramsString = decodeURIComponent(search.substring(1)); // 去掉 ? 并解码
      const paramsArray = paramsString.split("&"); // 分割字符串为数组
      const params = {}; // 初始化对象
      let paramsList = '';

      for (let i = 0; i < paramsArray.length; i++) {
        const parts = paramsArray[i].split("="); // 分割每个参数为键值对
        params[parts[0]] = parts[1]; // 添加到对象中
        paramsList += parts[0] + '=' + parts[1];
      }
      this.param = params; // 将参数对象保存到组件数据中
      if (this.param.invite_code && this.param.invite_code !== '') {
        this.loginData.invite_code = this.param.invite_code;
      }
      this.mobileLink = this.mobileLink + `?${paramsList}`;
    },
    // 获取验证码
    handleGetCode(loginData) {
      const formData = {
        account_id: Number(loginData.account_id),
        zone_id: Number(loginData.zone_id)
      }
      request.post('/sendCode', formData).then((response) => {
        console.log(response)
      })
    },
    // 登录
    handleLogin(loginData) {
      this.loginData.account_id = Number(loginData.account_id);
      this.loginData.zone_id = Number(loginData.zone_id);
      this.loginData.verify_code = Number(loginData.verify_code);
      request.post('/login', this.loginData).then((response) => {
        if (response.status === 1) {
          $$gaTracker.clickLoginConfirm();
          for (let i = 0; i < Object.keys(response.data).length; i++) {
            this.handleSetStorage(Object.keys(response.data)[i], response.data[Object.keys(response.data)[i]])
          }
          this.uuid = response.data.uuid;
          this.handleGetUserInfo();
          this.handleCloseDialog('showLoginModal');
        } else {
          this.tipsModalData = {
            title: 'Tips',
            des: [response.msg],
            btn: 'ok'
          };
          this.handleOpenDialog('showTipsModal');
        }
      })
    },
    // 获取用户信息
    handleGetUserInfo() {
      const formData = {
        uuid: this.uuid,
      }
      request.post('/userInfo', formData).then((response) => {
        if (response.status === 1) {
          this.userData = response.data;
          this.isLogin = true;
          this.handleGetRankList();
          for (let i = 0; i < Object.keys(response.data.task).length; i++) {
            this.taskData[Object.keys(response.data.task)[i]] = response.data.task[Object.keys(response.data.task)[i]]
          }
        }
      })
    },
    // 获取个人排行
    handleGetRankList() {
      const formData = {
        zone_type: this.loginData.zone_type,
        uuid: this.uuid
      }
      request.post('/userRankList', formData).then((response) => {
        if (response.status === 1) {
          this.ranksList = response.data;
        }
      })
    },
    // 获取结束倒计时
    handleGetActivityTime() {
      request.post('/activityTime').then((response) => {
        if (response.status === 1) {
          this.activityTime = response.data.day;
          this.handleJudgeTime(response.data.current_status);
        } else {
          this.isEnd = true;
        }
      })
    },
    // 签到
    handleSignTask() {
      if (this.isEnd) {
        this.handleEndTips();
        return false;
      }
      const formData = {
        uuid: this.uuid
      }
      request.post('/signTask', formData).then((response) => {
        if (response.status === 1) {
          $$gaTracker.clickSignIn();
          this.tipsModalData.des = ["You've successfully signed in."];
          this.tipsModalData.btn = 'ok';
          this.handleOpenDialog('showTipsModal')
          this.handleGetUserInfo();
        }
      })
    },
    // 切换作品排序
    handleChangeWorkSort() {
      this.handleGetWorkList(this.workSort)
    },
    // 获取作品列表
    handleGetWorkList() {
      const formData = {
        type: this.workSort
      }
      request.post('/worksVoteList', formData).then((response) => {
        if (response.status === 1) {
          this.cardList = [...response.data]
        }
      })
    },
    // 点击邀请
    handleShowShareModal() {
      this.dialogState.showShareModal = true;
      $$gaTracker.clickInvite()
    },
    // 每日任务
    handleSingleTask(name) {
      if (this.isEnd) {
        this.handleEndTips();
        return false;
      }
      if (!this.isLogin) {
        this.handleOpenDialog('showLoginModal');
        return false;
      }
      const formData = {
        uuid: this.uuid,
        type: name
      }
      request.post('/singleTask', formData).then((response) => {
        if (response.status === 1) {
          this.handleGetUserInfo();
          this.tipsModalData.title = 'Note'
          this.tipsModalData.des = ['Votes + 1'];
          this.tipsModalData.btn = 'ok';
          this.handleOpenDialog('showTipsModal');
        }
      })
    },
    // 锚点跳转
    handleGoAnchor(anchor, task) {
      if (!!anchor) {
        let anchorElement = document.getElementById(anchor);
        if (anchorElement) {
          window.scrollTo({
            top: !task || task !== 'task' ? anchorElement.offsetTop - 100 : anchorElement.offsetTop - 700,
            behavior: "smooth",
          });
          if (task === 'JoinTeam') {
            if (this.isEnd) {
              this.handleEndTips();
              return false;
            }
            if (!this.isLogin) {
              this.handleOpenDialog('showLoginModal');
            }
          }
        }
        // 没有的话，滚动到头部
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    // 获取战队排行
    handleGetGroupList() {
      const formData = {
        zone_type: this.loginData.zone_type,
      }
      request.post('/groupList', formData).then((response) => {
        if (response.status === 1) {
          this.groupList = response.data;
          this.handleSetBarWidth(this.totalWidth, this.groupList.lan_total_level_integral, this.groupList.hong_total_level_integral);
        }
      })
    },
    // 加入战队前二次确认
    handleJoinTips(id, index){
      if (this.isEnd) {
        this.handleEndTips();
        return false;
      }
      if (!this.isLogin) {
        this.handleOpenDialog('showLoginModal');
        return false;
      } else if (Number(this.userData?.new_old_status) === 0) {
        this.tipsModalData.title = 'Note'
        this.tipsModalData.des = ['To ensure fairness, the team competition of this open beta event is only available to players who signed up after the open beta test launched. We apologize in advance for any inconvenience this may bring to you!'];
        this.tipsModalData.btn = 'ok';
        this.handleOpenDialog('showTipsModal');
        return false;
      } else {
        this.tipsModalData.title = 'Join the Team'
        this.tipsModalData.des = ['Are you sure you want to join this team? ', 'Once you are in, you cannot leave team'];
        this.tipsModalData.btn = 'confirm';
        this.tipsModalData.data = {
          id: id,
          index: index
        };
        this.handleOpenDialog('showTipsModal');
      }
    },
    // 加入战队
    handleJoinGroup(data) {
      if (this.isEnd) {
        this.handleEndTips();
        return false;
      }
      const formData = {
        uuid: this.uuid,
        group_code: data.id
      }
      request.post('/joinGroup', formData).then((response) => {
        if (response.status === 1) {
          this.tipsModalData.title = 'Tips'
          this.tipsModalData.des = ["You've successfully joined the team"];
          this.tipsModalData.btn = 'ok';
          this.handleOpenDialog('showTipsModal');
          this.handleGetUserInfo();
          this.handleGetGroupList();
          if (data.index === 1) {
            $$gaTracker.clickJoinTeam1()
          } else {
            $$gaTracker.clickJoinTeam2()
          }
        } else {
          this.tipsModalData.title = 'Tips'
          this.tipsModalData.des = [response.msg];
          this.tipsModalData.btn = 'ok';
          this.handleOpenDialog('showTipsModal');
        }
      })
    },
    // 点击查看我的邮箱
    handleClickMyEmail() {
      if (!this.isLogin) {
        this.handleOpenDialog('showLoginModal');
      } else {
        if (this.userData.mailbox !== null) {
          this.dialogState.showLookEmailModal = true
        } else {
          this.dialogState.showEmailModal = true
        }

      }
    },
    // 点击修改邮箱信息
    handleShowEditEmail() {
      this.dialogState.showEmailModal = true;
      this.dialogState.showLookEmailModal = false;
    },
    // 添加/修改邮箱
    handleSubmitEmail(data) {
      if (!this.isLogin) {
        this.handleOpenDialog('showLoginModal');
        return false;
      }
      const formData = {
        uuid: this.uuid,
        account_id: data.account_id,
        user_mailbox: data.user_mailbox
      }
      request.post('/editMailbox', formData).then((response) => {
        if (response.status === 1) {
          this.tipsModalData.title = 'Tips'
          this.tipsModalData.des = ['Successfully saved!'];
          this.tipsModalData.btn = 'ok';
          this.handleCloseDialog('showEmailModal')
          this.handleOpenDialog('showTipsModal');
          this.handleGetUserInfo();
          $$gaTracker.clickSubmitEmail()
        } else {
          this.tipsModalData.title = 'Tips'
          this.tipsModalData.des = [response.msg];
          this.tipsModalData.btn = 'ok';
          this.handleOpenDialog('showTipsModal');
        }
      })
    },
    // 点击前往游戏
    handlePlayGame() {
      $$gaTracker.clickPlayGame()
    },
    handleClickShare(name) {
      const urlToShare = location.origin + location.pathname + `?invite_code=${this.uuid}`;
      this.handleCopyText(urlToShare, 'noDialog');
      switch (name) {
        case 'Facebook':
          $$gaTracker.clickShareFacebook();
          window.open("https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(urlToShare));
          break;
        case 'X':
          $$gaTracker.clickShareTwitter();
          window.open("https://twitter.com/intent/tweet?text=" + urlToShare);
          break;
        case 'Instagram':
          $$gaTracker.clickShareInstagram();
          window.open("https://www.instagram.com/");
          break;
        case 'Discord':
          $$gaTracker.clickShareDiscord();
          window.open("https://discord.com/");
          break;
        case 'WhatsApp':
          $$gaTracker.clickShareWhatsApp();
          window.open("https://web.whatsapp.com/");
          break;
        case 'Snapchat':
          $$gaTracker.clickShareSnapchat();
          window.open("https://web.snapchat.com/?ref=snapchat_dot_com_login_cta");
          break;
      }
    },
    // 点击邀请好友
    handleClickInvite() {
      let url = '';
      if (this.param !== null && Object.keys(this.param).length > 0) {
        url = location.origin + location.pathname + '?invite_code=' + this.userData.uuid;
        for (let i = 0 ; i < Object.keys(this.param).length; i++) {
          if (Object.keys(this.param)[i] !== 'invite_code') {
            url += '&' + Object.keys(this.param)[i] + '=' + this.param[Object.keys(this.param)[i]];
          }
        }
        this.shareData.link = url;
      } else {
        this.shareData.link = location.href + `?invite_code=${this.userData.uuid}`
      }
      this.handleOpenDialog('showLinkModal');
    },
    // 点击前往成功AI按钮
    handleClickComeCreate() {
      this.dialogState.showAICreateModal = true;
      $$gaTracker.clickComeCreateAI()
    },
    // 点击任务
    handleClickTask(name, index) {
      $$gaTracker.clickTask(name, index)
      if (!this.isLogin) {
        this.handleOpenDialog('showLoginModal');
        return false;
      }
      switch (name) {
        case 'browse_task': //DC浏览
          window.open("https://bit.ly/AIMasterWanted");
          break;
        case 'channel_task': //收集channel任务
          window.open(" https://bit.ly/aigcevent");
          break;
        case 'fb_task': //关注查看fb任务
          window.open("https://bit.ly/ShortVideoMasterWantted");
          break;
        case 'tiktok_task': //关注查看tiktok主页
          window.open("https://bit.ly/wortiktokofficial");
          break;
        case 'youtobe_task': //关注查看油管主页
          window.open("https://bit.ly/woryoutubeoffical");
          break;
        case 'reddit_task': //关注查看reddit主页
          window.open("https://bit.ly/worredditofficial");
          break;
      }
      if (this.taskData[name] < 1) {
        this.handleSingleTask(name);
      }
    },
    // 投票
    handleClickVote(id) {
      $$gaTracker.clickVote(id)
      if (this.isEnd) {
        this.handleEndTips();
        return false;
      }
      if (!this.isLogin) {
        this.handleOpenDialog('showLoginModal');
        return false;
      }
      if (this.isVoteIng) return false;
      if (this.taskData.vote_num < 1) {
        this.tipsModalData.title = 'Note'
        this.tipsModalData.des = ["You don't have enough votes! Complete quests to earn more votes."];
        this.tipsModalData.btn = 'ok';
        this.handleOpenDialog('showTipsModal');
        return false;
      }
      const formData = {
        uuid: this.uuid,
        works_id: id
      }
      this.isVoteIng = true;
      request.post('/voteOperation', formData).then((response) => {
        if (response.status === 1) {
          this.tipsModalData.title = 'Note'
          this.tipsModalData.des = ['Vote cast!'];
          this.tipsModalData.btn = 'ok';
          this.handleOpenDialog('showTipsModal');
          this.handleGetWorkList();
        } else {
          this.tipsModalData.title = 'Tips'
          this.tipsModalData.des = [response.msg];
          this.tipsModalData.btn = 'ok';
          this.handleOpenDialog('showTipsModal');
        }
        this.isVoteIng = false;
        this.handleGetUserInfo()
      })
    },
    // 复制方法
    handleCopyText(text, type = '', isClose = true) {
      let textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.style.position = 'fixed'; // Fix iOS jumping behavior
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();
      try {
        let successful = document.execCommand('copy');
        // eslint-disable-next-line no-unused-vars
        let msg = successful ? 'successful' : 'unsuccessful';
        if(isClose) {
          for (let i = 0; i < Object.keys(this.dialogState).length; i++) {
            this.dialogState[Object.keys(this.dialogState)[i]] = false;
          }
        }
        if (type !== 'noDialog') {
          this.tipsModalData = {
            title: 'Tips',
            des: ['Copy Success!'],
            btn: 'ok'
          }
          this.handleOpenDialog('showTipsModal');
        }

      } catch (err) {
        console.log('Oops, unable to copy');
      }
      document.body.removeChild(textarea);
    },
    // 监听滚动事件
    handleMonitorScroll() {
      window.onscroll = () => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop) {
          this.handleShowNav(scrollTop);
        }
      };
    },
    // 处理nav是否显示
    handleShowNav(scrollTop) {
      const mt1 = document.getElementById('modules_1').offsetTop - 200;
      const mt2 = document.getElementById('modules_2').offsetTop - 200;
      const mt3 = document.getElementById('modules_3').offsetTop - 200;
      const mt4 = document.getElementById('modules_4').offsetTop - 200;
      const mt5 = document.getElementById('modules_5').offsetTop - 600;
      this.isShowNavBar = scrollTop > mt1;
      if (scrollTop >= mt1 && scrollTop < mt2 ) {
        this.navActive = 0;
      } else if (scrollTop >= mt2 && scrollTop < mt3) {
        this.navActive = 1;
      } else if (scrollTop >= mt3 && scrollTop < mt4) {
        this.navActive = 2;
      } else if (scrollTop >= mt4 && scrollTop < mt5) {
        this.navActive = 3;
      } else if (scrollTop >= mt5) {
        this.navActive = 4;
      }
    },
    // 播放视频
    handlePlayVideo(vid) {
      this.playVideoLink = vid;
      this.handleOpenDialog('showVideoModal');
    },
    // 日期判断
    handleJudgeTime(status) {
      // const timeStamp = new Date('2023/07/05 0:00'.replace(/-/g, '/')).getTime();
      if (status !== 1) {
        this.isShowPage = true;
        if (status === 3) {
          this.isEnd = true;
        } else if (status === 4) {
          this.isEnd = true;
        }
      }
    },
    // 处理进度条显示
    handleSetBarWidth(obj, data1, data2) {
      const width1 = data1 / (data1 + data2) * 100;
      const width2 = data2 / (data1 + data2) * 100;
      obj.left = width1 >= 80 ? 80 : width1 <=20 ? 20 : width1;
      obj.right = width2 >= 80 ? 80 : width2 <=20 ? 20 : width2;
    },
    // 活动结束统一提示
    handleEndTips() {
      this.tipsModalData.title = 'Note'
      this.tipsModalData.des = ["This event has ended, but for the next 7 days, you can still use your remaining points to draw rewards. Afterwards, the points will expire, but you can still access 'My Package'."];
      this.tipsModalData.btn = 'ok';
      this.handleOpenDialog('showTipsModal');
    },
    // 实例化策划swiper
    handleCreatePlanSwiper() {
      this.planSwiper = new Swiper('.plan-swiper', {
        loop: true,
        autoplay: false,
        observer: true,
        observeSlideChildren: true,
        pagination: {
          el: ".plan-pagination",
        },
        navigation: {
          nextEl: ".btn-plan-next",
          prevEl: ".btn-plan-prev",
        },
      });
    },
    // 实例化新版本swiper
    handleCreateNewVersionSwiper() {
      this.newVersionSwiper = new Swiper('.newVersion-swiper', {
        loop: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 5000,
        },
        observer: true,
        observeSlideChildren: true,
        navigation: {
          nextEl: ".btn-newVersion-next",
          prevEl: ".btn-newVersion-prev",
        },
        pagination: {
          el: ".newVersion-pagination",
        },
      });
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/views/css/index.scss";
@import "@/components/Modal/css/dialog.css";
</style>
<style lang="scss">
.swiper-pagination-bullet {
  opacity: 1!important;
  margin: 0 17px;
  width: 17px;
  height: 17px;
  background: transparent;
  border-radius: 50%;
  border: 1px solid #ead194;
}

.swiper-pagination-bullet-active {
  background: url("./images/icon-pagination.png") no-repeat center/contain;
  border: none;
}
</style>
