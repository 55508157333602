<template>
<div class="modal_bg" v-if="isShowDia">
  <div class="modal modal_video">
    <a href="javascript:;" class="icon_close" @click="handleCloseDialog" />
    <div id="player"></div>
  </div>
</div>
</template>

<script>

export default {
  name: "videoModal",
  props: {
    isShowDia: Boolean,
    videoLink: String
  },
  data() {
    return {
      player: null
    }
  },
  methods: {
    handleCloseDialog() {
      this.$emit('closeDialog', 'showVideoModal');
      this.player.destroy();
    },
    onYouTubeIframeAPIReady() {
      this.player = new YT.Player('player', {
        height: '100%',
        width: '100%',
        videoId: this.videoLink,
        events: {
          'onReady': this.onPlayerReady,
          // 'onStateChange': onPlayerStateChange
        }
      });
    },
    onPlayerReady(event) {
      event.target.playVideo();
    },

  },
  updated() {
    this.onYouTubeIframeAPIReady();
  }
}
</script>

<style scoped lang="scss">
.modal_video{
  width: 1200px!important;
  height: 675px;
  background: gray;
  padding:0 !important;

  #player{
    width: 100%;
    height: 100%;
  }
}
</style>
