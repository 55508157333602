<template>
<div class="modal_bg" v-if="isShowDia">
  <div class="modal modal_485">
    <a href="javascript:;" class="icon_close" @click="handleCloseDialog" />
    <div class="modal_title">{{title}}</div>
    <div class="text_box">
      <p v-for="(item, index) in text" :key="index">{{item}}</p>
    </div>
    <div class="link_box">
      <p>Invitation Link：</p>
      <input :value="link" disabled class="link">
    </div>
    <a href="javascript:;" :class="['modal_btn', 'btn_copy']" @click="handleCopyText(link)" />
  </div>
</div>
</template>

<script>
export default {
  name: "linkModal",
  props: {
    isShowDia: Boolean,
    title: String,
    text: Array,
    link: String
  },
  methods: {
    handleCloseDialog() {
      this.$emit('closeDialog', 'showLinkModal');
    },
    handleCopyText(text) {
      this.$emit('copy', text)
    },
  }
}
</script>

<style scoped lang="scss">
.text_box{
  height: 132px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;

  p{
    font-size: 24px;
    color: #fff;
    text-align: center;
    width: 100%;
    line-height: 34px;
  }
}

.link_box{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 66px;

  p{
    font-size: 30px;
    color: #fff6ce;
  }

  input{
    width: 368px;
    height: 50px;
    line-height: 48px;
    border:1px solid #d2ad64;
    background: #372819;
    font-size: 24px;
    color: #d6c593;
    padding-left: 12px;
  }
}

.btn_copy{
  margin:0 auto;
}
</style>
