<template>
<div class="modal_bg" v-if="isShowDia">
  <div class="modal modal_485">
    <a href="javascript:;" class="icon_close" @click="handleCloseDialog" />
    <div class="modal_title">Please fill in your email</div>
    <ul class="info">
      <li>
        <p>Game ID：</p>
        <input
            type="text"
            v-model="email.account_id"
            @input="handleInput"
        >
      </li>
      <li>
        <p>Email：</p>
        <input
            type="text"
            v-model="email.user_mailbox"
        >
      </li>
    </ul>
    <p class="text">
      1.Please fill in your email (For Individual Ranking Recipient ONLY)<br/>
      2.The deadline for collecting your game ID and email address is 1/15/2024. Failure to provide a valid email information during the collection period will be considered as forfeiting the reward.</p>
    <div :class="['btn-box', 'btn-box-1']">
      <a href="javascript:;" :class="['modal_btn', 'btn_confirm', !bothInputsFilled && 'null']" @click="handleSubMitEmail" />
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "emailModal",
  props: {
    isShowDia: Boolean,
  },
  computed: {
    // 只允许输入数字，其他字符被忽略
    NumberValue() {
      return this.email.account_id.replace(/[^\d]/g, '');
    },
    bothInputsFilled() {
      return this.email.account_id !== '' && this.email.user_mailbox !== '';
    }
  },
  data() {
    return {
      email: {
        account_id: "",
        user_mailbox: "",
      }
    }
  },
  methods: {
    handleCloseDialog() {
      this.$emit('closeDialog', 'showEmailModal');
    },
    handleInput() {
      this.email.account_id = this.NumberValue.toString();
    },
    handleSubMitEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (this.email.account_id !== '') {
        if (emailPattern.test(this.email.user_mailbox)) {
          this.$emit('submitEmail', this.email);
          setTimeout(() => {
            this.modify = false
          }, 1000)
        } else {
          alert('email error')
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.text {
  font-size: 18px;
  padding: 0 30px;
  color: #fcf9dc;
  margin: 20px auto;
}
.info{
  padding:0 10px 0;
  margin: 25px;
  li{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 20px;

    p{
      width: 160px;
      text-align: right;
      font-size: 26px;
      color: #fcf9dc;
      line-height: 50px;
      margin-right: 12px;
    }

    input,
    select,
    textarea{
      border:1px solid #ddb76a;
      border-radius: 8px;
      background: #372819;
      color: #e3d8b8;
      font-size: 18px;
    }

    input{
      height: 50px;
      line-height: 48px;
      padding-left: 8px;
      width: 360px;
    }
  }
}

.btn-box {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;

  &.btn-box-1 {
    justify-content: center;
  }

  &.btn-box-2 {
    justify-content: space-around;
  }

  .modal_btn.null{
    -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
    filter: grayscale(100%);
    pointer-events: none;
  }

  .btn_modify {
    display: block;
    width: 298px;
    height: 84px;
    background: url("../images/btn-modify.png") no-repeat center/contain;
  }
}
</style>
