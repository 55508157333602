const gaTrackerBaseEvent = {
  // 发送埋点
  send: (event_name, event_options) => {
    // eslint-disable-next-line no-undef
    gtag('event', event_name, event_options);
  }
};

const $$gaTracker = {
  /**
   * 点击立即下载
   */
  clickDownLoad: () => {
    gaTrackerBaseEvent.send('click_Download_now', {});
  },
  /**
   * 点击加入战队1
   */
  clickJoinTeam1: () => {
    gaTrackerBaseEvent.send('click_Join_Team_1', {});
  },
  /**
   * 点击加入战队2
   */
  clickJoinTeam2: () => {
    gaTrackerBaseEvent.send('click_Join_Team_2', {});
  },
  /**
   * 点击签到
   */
  clickSignIn: () => {
    gaTrackerBaseEvent.send('click_sign_in', {});
  },
  /**
   * 点击分享
   */
  clickShareLink: () => {
    gaTrackerBaseEvent.send('click_share', {});
  },
  /**
   * 点击facebook分享
   */
  clickShareFacebook: () => {
    gaTrackerBaseEvent.send('click_Facebook_shares', {});
  },
  /**
   * 点击twitter分享
   */
  clickShareTwitter: () => {
    gaTrackerBaseEvent.send('click_Twitter_shares', {});
  },
  /**
   * 点击Instagram分享
   */
  clickShareInstagram: () => {
    gaTrackerBaseEvent.send('click_Instagram_shares', {});
  },
  /**
   * 点击Discord分享
   */
  clickShareDiscord: () => {
    gaTrackerBaseEvent.send('click_Discord_shares', {});
  },
  /**
   * 点击WhatsApp分享
   */
  clickShareWhatsApp: () => {
    gaTrackerBaseEvent.send('click_WhatsApp_shares', {});
  },
  /**
   * 点击Snapchat分享
   */
  clickShareSnapchat: () => {
    gaTrackerBaseEvent.send('click_Snapchat_shares', {});
  },
  /**
   * 点击邀请好友
   */
  clickInviteFriends: () => {
    gaTrackerBaseEvent.send('click_Invite_friends', {});
  },
  /**
   * 点击kv登录按钮
   */
  clickLoginBtn: () => {
    gaTrackerBaseEvent.send('click_Home_page_login', {});
  },
  /**
   * 点击登录弹窗登录按钮
   */
  clickLoginConfirm: () => {
    gaTrackerBaseEvent.send('click_Login_pop-up_login_button', {});
  },
  /**
   * 点击前往游戏
   */
  clickPlayGame: () => {
    gaTrackerBaseEvent.send('click_Starting_clearance', {});
  },
  /**
   * 点击邀请
   */
  clickInvite: () => {
    gaTrackerBaseEvent.send('click_Invite_friends', {});
  },
  /**
   * 点击每日任务
   */
  clickTask: (name, index) => {
    gaTrackerBaseEvent.send('click_Join', {
      task_name: name,
      task_index: index
    });
  },
  /**
   * 点击投票
   */
  clickVote: (id) => {
    gaTrackerBaseEvent.send('click_vote', {
      work_id: id
    });
  },
  /**
   * 点击生成AI
   */
  clickCreateAI: (id) => {
    gaTrackerBaseEvent.send(`click_AI_Tool_${id}`, {
      create_AI_Tool_ID: id
    });
  },
  /**
   * 点击投稿
   */
  clickSubmit: () => {
    gaTrackerBaseEvent.send('click_Go_for_submission', {});
  },
  /**
   * 点击保存邮箱
   */
  clickSubmitEmail: () => {
    gaTrackerBaseEvent.send('click_submit_email_button', {});
  },
  /**
   * 点击前往生成并投稿你的AI图
   */
  clickComeCreateAI: () => {
    gaTrackerBaseEvent.send('click_Come_create_your_own_AI_image', {});
  },
};

export default $$gaTracker;
