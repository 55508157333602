<template>
<div class="modal_bg" v-if="isShowDia">
  <div class="modal modal_631">
    <a href="javascript:;" class="icon_close" @click="handleCloseDialog" />
    <div class="modal_title">Daily Sharing</div>
    <div class="text_box">
      <p>Share the event with the following platforms and</p>
      <p>claim generous rewards with your friends!</p>
    </div>
    <ul class="share_list">
      <li v-for="(item, index) in shareList" :key="index" class="share_item" @click="handleClickShare(item.name)">
        <img :src="item.icon" :alt="item.name" />
        <p>{{item.name}}</p>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  name: "shareModal",
  props: {
    isShowDia: Boolean
  },
  data() {
    return {
      shareList: [
        {
          name: 'Facebook',
          icon: require('@/components/Modal/images/icon-Facebook.png'),
          link: ''
        },
        {
          name: 'X',
          icon: require('@/components/Modal/images/icon-Twitter.png'),
          link: ''
        },
        {
          name: 'Instagram',
          icon: require('@/components/Modal/images/icon-Instagram.png'),
          link: ''
        },
        {
          name: 'Discord',
          icon: require('@/components/Modal/images/icon-Discord.png'),
          link: ''
        },
        {
          name: 'WhatsApp',
          icon: require('@/components/Modal/images/icon-WhatsApp.png'),
          link: ''
        },
        {
          name: 'Snapchat',
          icon: require('@/components/Modal/images/icon-Snapchat.png'),
          link: ''
        }
      ]
    }
  },
  methods: {
    handleCloseDialog() {
      this.$emit('closeDialog', 'showShareModal');
    },
    handleClickShare(name) {
      this.$emit('clickShare', name);
    }
  }
}
</script>

<style scoped lang="scss">
.text_box{
  height: 125px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;

  p{
    font-size: 24px;
    color: #fff;
    text-align: center;
    width: 100%;
    line-height: 34px;
  }
}

.share_list{
  width: 500px;
  height: 350px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;

  .share_item{
    width: 114px;
    cursor: pointer;
    transition: all ease-in-out 0.1s;

    &:hover{
      filter: brightness(1.2);
      transform: scale(1.05);
    }

    &:nth-child(2),
    &:nth-child(5),{
      margin: 0 35px;
    }

    img{
      display: block;
      width: 114px;
      height: 114px;
      margin: 0 auto 15px;
    }

    p{
      font-size: 20px;
      color: #f9f1ce;
      text-align: center;
    }
  }
}
</style>
