<template>
<div class="modal_bg" v-if="isShowDia">
  <div class="modal modal_485">
    <a href="javascript:;" class="icon_close" @click="handleCloseDialog" />
    <div class="modal_title">Reward Rules</div>
    <div class="rule_list">
      <p class="tit">
        Team Ranking
      </p>
      <p class="info indent">
        All players in the winning team will obtain Rare Summoning Crystal * 5, Huge Stamina Potion * 1, 100% EXP Bonus * 1. Keep an eye out for your in-game mailbox. Rewards will be issued within 7 working days after the event ends.
      </p>
      <p class="tit">
        Individual Ranking
      </p>
      <p class="info indent">
        Upon winning rewards for Individual Ranking, players must tap on “Check email Address” to fill in personal information. The rewards will be issued within 30 working days after the event ends. Failure to enter email and game ID within 7 days after the event ends or entering the wrong information will result in forfeited rewards.
      </p>
      <p class="info indent">
        Virtual items will be issued through your in-game mailbox, while Amazon Gift Cards will be issued after the event ends. This page is the only way for information collection and does not charge anything. Please beware of fraud and do not remit money to others.
      </p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "ruleModal",
  props: {
    isShowDia: Boolean
  },
  methods: {
    handleCloseDialog() {
      this.$emit('closeDialog', 'showRuleModal');
    }
  }
}
</script>

<style scoped lang="scss">
//.modal_485{
//  padding-right: 30px!important;
//}
.rule_list{
  height: 344px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 25px;
  margin-top: 8px;

  &::-webkit-scrollbar {
    width: 3px;  /* 滚动条宽度 */
  }

  &::-webkit-scrollbar-track {
    background-color: #785a33; /* 滚动条背景颜色 */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f9e9a4; /* 滚动条滑块颜色 */
    border-radius: 3px;  /* 滑块边缘弧度 */
  }

  p{
    line-height: 34px;
    font-size: 24px;
    color: #f7f3df;

    &.indent{
      text-indent: 2em;
    }

    &.tit {
      margin: 10px 0;
    }
  }
}
</style>
