<template>
<div class="modal_bg" v-if="isShowDia">
  <div class="modal modal_485">
    <a href="javascript:;" class="icon_close" @click="handleCloseDialog" />
    <div class="modal_title">Current email</div>
    <ul class="info">
      <li>
        <p>Game ID：</p>
        <input
            type="text"
            v-model="emailData.account_id"
            disabled
        >
      </li>
      <li>
        <p>Email：</p>
        <input
            type="text"
            v-model="emailData.mailbox"
            disabled
        >
      </li>
    </ul>
    <div :class="['btn-box', 'btn-box-2']">
      <a href="javascript:;" :class="['modal_btn', 'btn_modify']" @click="handleClickModify" />
      <a href="javascript:;" :class="['modal_btn', 'btn_ok']" @click="handleCloseDialog" />
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "lookEmailModal",
  props: {
    isShowDia: Boolean,
    emailData: Object,
  },
  data() {
    return {

    }
  },
  methods: {
    handleCloseDialog() {
      this.$emit('closeDialog', 'showLookEmailModal');
    },
    handleClickModify() {
      this.$emit('editEmail');
    }
  }
}
</script>

<style scoped lang="scss">
.info{
  padding:60px 10px 0;
  margin-bottom: 40px;
  li{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 20px;

    p{
      width: 200px;
      text-align: right;
      font-size: 26px;
      color: #fcf9dc;
      line-height: 50px;
      margin-right: 12px;
    }

    input,
    select,
    textarea{
      border:1px solid #ddb76a;
      border-radius: 8px;
      background: #372819;
      color: #e3d8b8;
      font-size: 18px;
    }

    input{
      height: 50px;
      line-height: 48px;
      padding-left: 8px;
      width: 360px;
    }
  }
}

.btn-box {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;

  &.btn-box-1 {
    justify-content: center;
  }

  &.btn-box-2 {
    justify-content: space-around;
  }

  .modal_btn.null{
    -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
    filter: grayscale(100%);
    pointer-events: none;
  }

  .btn_modify {
    display: block;
    width: 298px;
    height: 84px;
    background: url("../images/btn-modify.png") no-repeat center/contain;
  }
}
</style>
