<template>
<div class="modal_bg" v-if="isShowDia">
  <div class="modal modal_631">
    <a href="javascript:;" class="icon_close" @click="handleCloseDialog" />
    <div class="modal_title">Event Rules</div>
    <div class="text">
      <p>Commanders who submit images during the voting phase of the WoR</p>
      <p>AIGC Event have a chance at winning Diamond x800 for excellent works!</p>
    </div>
    <div class="main">
      <p class="tit">Come create your own AI image!</p>
      <ul class="ai-list">
        <li>
          <p>2D AI Generator Tool</p>
          <a href="https://bit.ly/2dtool1" class="btn-go btn-go1" target="_blank" @click="handleClickGoCreate(1)" />
        </li>
        <li>
          <p>3D AI Generator Tool</p>
          <a href="https://bit.ly/3dtool1" class="btn-go btn-go1" target="_blank" @click="handleClickGoCreate(2)" />
        </li>
        <li>
          <p>Sdonline Tool</p>
          <a href="https://bit.ly/sdonline125" class="btn-go btn-go1" target="_blank" @click="handleClickGoCreate(3)" />
        </li>
      </ul>
      <div class="tips">
        <p>The programs listed above only represent a few common tools. </p>
        <p>Commanders are by no means limited to these options.</p>
        <p>Note that WoR is not legally responsible for any images or effects </p>
        <p>generated by the tools listed.</p>
      </div>
    </div>
    <div class="submit">
      <p>Submit your images!</p>
      <a href="https://bit.ly/aigcevent" class="btn-go btn-go2" target="_blank" @click="handleClickSubmit" />
    </div>
  </div>
</div>
</template>

<script>
import $$gaTracker from "@/utils/google-analytics-tracker";

export default {
  name: "AICreateModal",
  props: {
    isShowDia: Boolean,
    title: String,
  },
  methods: {
    handleCloseDialog() {
      this.$emit('closeDialog', 'showAICreateModal');
    },
    handleClickGoCreate(id) {
      $$gaTracker.clickCreateAI(id)
    },
    handleClickSubmit() {
      $$gaTracker.clickSubmit()
    }
  }
}
</script>

<style scoped lang="scss">
.text {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  height: 158px;

  p {
    width: 100%;
    text-align: center;
    font-size: 24px;
    color: #fff;
    line-height: 36px;
  }
}

.main {
  width: 580px;
  height: 234px;
  border-radius: 12px;
  background: rgba(0,0,0,.3);
  margin: 0 auto 36px;
  padding: 16px 0 0;

  .tit {
    font-size: 20px;
    color: #fff;
    text-align: center;
    margin-bottom: 18px;
  }

  .ai-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    li {
      width: 180px;

      p {
        line-height: 28px;
        color: #fff;
        font-size: 16px;
        text-align: center;
      }

      .btn-go1 {
        display: block;
        width: 100%;
        height: 39px;
        background: url("../images/btn-task-go2.png") no-repeat center/contain;

        &:hover {
          transition: all linear 0.2s;
          transform: scale(1.03);
        }
      }
    }
  }

  .tips {
    font-size: 16px;
    line-height: 20px;
    color: #e1be9b;
    text-align: center;
  }
}

.submit {
  width: 578px;
  height: 28px;
  border-radius: 12px;
  background: rgba(0,0,0,.3);
  padding-left: 60px;
  position: relative;
  margin: 0 auto;

  p {
    font-size: 20px;
    color: #fff;
    line-height: 28px;
  }

  .btn-go2 {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    right: 27px;
    width: 132px;
    height: 39px;
    background: url("../images/btn-task-go1.png") no-repeat center/contain;

    &:hover {
      transition: all linear 0.2s;
      transform: scale(1.03);
    }
  }
}
</style>
