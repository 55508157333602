<template>
  <div id="app">
    <Main />
  </div>
</template>

<script>
import Main from '@/views/index.vue'

export default {
  name: 'App',
  components: {
    Main
  }
}
</script>

<style lang="scss">
#app {
  font-face{
    font-family: "FZ";
    src: url("@/assets/font/SOURCEHANSERIFCN-REGULAR.ttf") format("ttf");
  }
  font-family: FZ, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  background: #20160c;
}
</style>
