import axios from 'axios'

const request = axios.create({
    // baseURL: 'http://192.168.17.15:7777/enArea/',
    baseURL: 'https://dec-eventapi.watcherofrealms.com/enArea/',
    timeout: 5000,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json; charset=UTF-8;"
    }
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    config.headers['zone'] = 1;
    config.headers['token'] = localStorage.getItem('token');
    return config
}, error => {
    return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        // // 如果是返回的文件
        // if (response.config.responseType === 'blob') {
        //     return res
        // }
        // // 兼容服务端返回的字符串数据
        // if (typeof res === 'string') {
        //     res = res ? JSON.parse(res) : res
        // }
        return response.data;
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)

export default request;
